<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form ref="form" @submit.prevent="submitForm">
              <div class="row">
                <div class="col-6">
                  <v-text-field
                    v-model="form.searchKeyword"
                    :disabled="datatable.loading"
                    :label="$t('SHARED.KEYWORD')"
                    :hint="$t('MEMBER_CARD.SEARCH.KEYWORD_HINT')"
                    prepend-icon="mdi-file-document-box-search-outline"
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-autocomplete
                    v-model="form.generateRefCode"
                    :disabled="datatable.loading"
                    :items="form.generateRefCodeItems"
                    :loading="form.generateRefCodeLoading"
                    :search-input.sync="form.generateRefCodeSearch"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('MEMBER_CARD.SEARCH.GENERATE_REF_CODE')"
                    :placeholder="
                      $t('SHARED.START_TYPING_TO_SEARCH') +
                        $t('MEMBER_CARD.SEARCH.GENERATE_REF_CODE')
                    "
                    prepend-icon="mdi-database-search"
                    return-object
                    clearable
                  ></v-autocomplete>
                </div>
                <div class="col-3">
                  <v-autocomplete
                    v-model="form.memberClassCode"
                    :disabled="datatable.loading"
                    :items="form.memberClassCodeItems"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('MEMBER_CARD.SEARCH.MEMBER_CLASS')"
                    :placeholder="
                      $t('SHARED.START_TYPING_TO_SEARCH') +
                        $t('MEMBER_CARD.SEARCH.MEMBER_CLASS')
                    "
                    prepend-icon="mdi-database-search"
                    return-object
                    clearable
                  ></v-autocomplete>
                </div>
                <div class="col-3">
                  <v-autocomplete
                    v-model="form.registerStatus"
                    :disabled="datatable.loading"
                    :items="form.registerStatusItems"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('MEMBER_CARD.SEARCH.IS_REGISTERED')"
                    :placeholder="$t('SHARED.START_TYPING_TO_SEARCH')"
                    prepend-icon="mdi-database-search"
                    return-object
                    clearable
                  ></v-autocomplete>
                </div>
                <div class="col-3">
                  <v-autocomplete
                    v-model="form.expirationStatus"
                    :disabled="datatable.loading"
                    :items="form.expirationStatusItems"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('MEMBER_CARD.SEARCH.IS_EXPIRED')"
                    :placeholder="$t('SHARED.START_TYPING_TO_SEARCH')"
                    prepend-icon="mdi-database-search"
                    return-object
                    clearable
                  ></v-autocomplete>
                </div>
                <v-col cols="12" md="3">
                  <v-menu
                  ref="dialogexpireMonthModal"
                    v-model="form.expireMonthModal"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    >
                  <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedExpireMonth"
                        :disabled="form.loading"
                        :label="$t('MEMBER_CARD.SEARCH.EXPIRE_MONTH')"
                        :rules="form.expireMonthRules"
                        required
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.expireMonth"
                      type="month"
                      locale="th"
                      :disabled="form.loading"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="default"
                        @click="form.expireDateModal = false"
                        >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogexpireMonthModal.save(form.expireMonth)"
                        >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                      >
                    </v-date-picker>     
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    ref="registeredDateModal"
                    v-model="form.registeredDateModal"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedRegisteredDate"
                        :disabled="form.loading"
                        :label="$t('MEMBER_CARD.MANAGE.REGISTERED_DATE')"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.registeredDate"
                      locale="th"
                      :disabled="form.loading"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="default"
                        @click="form.registeredDateModal = false"
                        >{{ $t("SHARED.CANCEL_BUTTON") }}</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.registeredDateModal.save(form.registeredDate)"
                        >{{ $t("SHARED.CHOOSE_BUTTON") }}</v-btn
                      >
                    </v-date-picker>     
                  </v-menu>
                </v-col>
              </div>
              
              <div class="row">
                <div class="col-12">
                  <v-btn
                    :disabled="datatable.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon v-if="!datatable.loading" left
                      >mdi-database-search</v-icon
                    >
                    <v-icon v-if="datatable.loading" left
                      >fa fa-spinner fa-spin</v-icon
                    >
                    {{ $t("SHARED.SEARCH_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="datatable.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                  <!-- <v-btn
                    :disabled="datatable.loading"
                    color="warning"
                    class="mr-4"
                    tile
                    @click.prevent="Export"
                  >
                    <v-icon v-if="!datatable.loading" left
                      >fas fa-file-export</v-icon
                    >
                    <v-icon v-if="datatable.loading" left
                      >fa fa-spinner fa-spin</v-icon
                    >
                    {{ $t("SHARED.EXPORT_BUTTON") }}
                  </v-btn> -->
                </div>
              </div>
            </v-form>

            <v-divider class="my-4"></v-divider>

            <v-subheader
              ><v-icon left>mdi-table-search</v-icon>
              {{ $t("SHARED.SEARCH_RESULT") }}</v-subheader
            >

            <v-data-table
              :headers="datatable.headers"
              :items="datatable.items"
              :loading="datatable.loading"
              :options.sync="datatable.options"
              :server-items-length="datatable.total"
              :footer-props="{
                'items-per-page-options': [30, 50, 100],
              }"
              multi-sort
             >
              <template v-slot:item.expireMonth="{ item }">
                {{ item.expireDate | iso8601ToFormat("MM/YYYY") }}
              </template>
              <template v-slot:item.isRegistered="{ item }">
                <v-chip
                  class="ma-2"
                  color="green"
                  text-color="white"
                  small
                  v-if="item.isRegistered"
                >
                  <v-avatar left>
                    <v-icon small>fa fa-check-circle</v-icon>
                  </v-avatar>
                  {{ $t("SHARED.REGISTERED") }}
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="red"
                  text-color="white"
                  small
                  v-else
                >
                  <v-avatar left>
                    <v-icon small>fa fa-minus-circle</v-icon>
                  </v-avatar>
                  {{ $t("SHARED.NOT_REGISTER") }}
                </v-chip>
              </template>
              <template v-slot:item.registeredDate="{ item }">
                {{ item.registeredDate | iso8601ToFormat("DD/MM/YYYY") }}
              </template>
              <template v-slot:item.batchCode="{ item }">
                <!-- <span style="margin-top: 8px; display: inline-block;">{{
                  item.batchCode
                }}</span> -->
                <v-btn
                  icon
                  color="blue"
                  class="pull-right"
                  @click.prevent="downloadExcel(item.batchCode)"
                >
                  <v-icon color="blue" small>fa fa-file-excel</v-icon>
                </v-btn>
                <v-dialog v-model="item.cardDialog" width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="indigo" v-on="on" class="pull-right">
                      <v-icon>mdi-credit-card</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6">
                          <p>
                            {{ $t("MEMBER_CARD.GENERATE.CARD_PICTURE_FRONT") }}
                          </p>
                          <p>
                            <v-img
                              :src="
                                '/Mobile/Api/DownloadFile/Byte?FileInfoId=' +
                                  item.cardFrontFileId
                              "
                              :lazy-src="
                                '/Mobile/Api/DownloadFile/Byte?FileInfoId=' +
                                  item.cardFrontFileId
                              "
                              class="grey lighten-2"
                            >
                            </v-img></p
                        ></v-col>
                        <v-col cols="6">
                          <p>
                            {{ $t("MEMBER_CARD.GENERATE.CARD_PICTURE_BACK") }}
                          </p>
                          <p>
                            <v-img
                              :src="
                                '/Mobile/Api/DownloadFile/Byte?FileInfoId=' +
                                  item.cardBackFileId
                              "
                              :lazy-src="
                                '/Mobile/Api/DownloadFile/Byte?FileInfoId=' +
                                  item.cardBackFileId
                              "
                              class="grey lighten-2"
                            >
                            </v-img>
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="grey darken-1"
                        text
                        @click="item.cardDialog = false"
                        >{{ $t("SHARED.CLOSE_BUTTON") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-data-table>

            <v-dialog
              v-model="datatable.loading"
              persistent
              hide-overlay
              width="300"
            >
              <v-card>
                <v-card-title class="headline">
                  {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                >
                <v-card-text>
                  <p>
                    {{ $t("SHARED.PROCESSING") }}
                  </p>
                  <v-progress-linear
                    indeterminate
                    color="amber lighten-3"
                    class="mb-3"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import moment from "moment";
import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: {
    KTPortlet,
  },
  data() {
    return {
      form: {
        loading: false,
        searchKeyword: "",
        generateRefCode: null,
        generateRefCodeSearch: "",
        generateRefCodeLoading: false,
        generateRefCodeItems: [],
        registeredDate: null,
        registeredDateModal: false,
        registeredDateFormatted: "",
        expireMonth: null,
        expireMonthModal: false,
        expireMonthFormatted: "",
        memberClassCode: null,
        memberClassCodeItems: [
          {
            text: this.$t("SYS_VARIABLE.MEMBER_CLASS_NORMAL"),
            value: "MEMBER_CLASS_NORMAL",
          },
          {
            text: this.$t("SYS_VARIABLE.MEMBER_CLASS_GOLD"),
            value: "MEMBER_CLASS_GOLD",
          },
        ],
        registerStatus: null,
        registerStatusItems: [
          {
            text: this.$t("SHARED.REGISTERED"),
            value: "Y",
          },
          {
            text: this.$t("SHARED.NOT_REGISTER"),
            value: "N",
          },
        ],
        expirationStatus: null,
        expirationStatusItems: [
          {
            text: this.$t("SHARED.EXPIRED"),
            value: "Y",
          },
          {
            text: this.$t("SHARED.NOT_EXPIRED"),
            value: "N",
          },
        ],
        expireMonthRules: [
          (v) => !!v || this.$t("MEMBER_CARD.MANAGE.EXPIRE_MONTH_REQUIRED"),
        ],
      },
      datatable: {
        headers: [
          {
            text: this.$t("MEMBER_CARD.SEARCH.MEMBER_CLASS"),
            value: "memberClassName",
            align: "center",
          },
          {
            text: this.$t("MEMBER_CARD.SEARCH.CARD_NUMBER"),
            value: "cardNumber",
            align: "center",
          },
          {
            text: this.$t("MEMBER_CARD.SEARCH.SECURE_CODE"),
            value: "secureCode",
            align: "center",
          },
          {
            text: this.$t("MEMBER_CARD.SEARCH.EXPIRE_DATE"),
            value: "expireMonth",
            align: "center",
          },
          {
            text: this.$t("MEMBER_CARD.SEARCH.IS_REGISTERED"),
            value: "isRegistered",
            align: "center",
          },
          {
            text: this.$t("MEMBER_CARD.SEARCH.REGISTERED_DATE"),
            value: "registeredDate",
            align: "center",
          },
          {
            text: this.$t("MEMBER_CARD.SEARCH.GENERATE_REF_CODE"),
            value: "generateRefCode",
          },
          {
            text: this.$t("MEMBER_CARD.SEARCH.BATCH_CODE"),
            value: "batchCode",
            sortable: false,
          },
        ],
        items: [],
        total: 0,
        loading: true,
        options: {
          sortBy: ["cardNumber"],
          sortDesc: [true],
          itemsPerPage: 30,
        },
      },
    };
  },
  methods: {
    downloadExcel(batchCode) {
      window.open(
        "/Files/Download/MemberCard/" + batchCode + "?token=" + this.token,
        "_blank"
      );
    },
    submitForm() {
      this.getDataFromApi().then((data) => {
        this.datatable.total = data.total;
        this.datatable.items = data.items;
      });
    },
    resetForm() {
      this.$refs.form.reset();
      this.form.expireMonthFormatted = ""
      this.form.registeredDateFormatted = ""
      this.submitForm();
    },
    getDataFromApi() {
      this.datatable.loading = true;
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/MemberCards/Search", {
          groupBy: this.datatable.options.groupBy,
          groupDesc: this.datatable.options.groupDesc,
          itemsPerPage: this.datatable.options.itemsPerPage,
          page: this.datatable.options.page,
          sortBy: this.datatable.options.sortBy,
          sortDesc: this.datatable.options.sortDesc,
          searchKeyword: this.form.searchKeyword,
          expireMonthString: this.form.expireMonthFormatted
            ? moment(this.form.expireMonth, "YYYY-MM").endOf("month").format("DD/MM/YYYY")
            : null,
          registeredDateString: this.form.registeredDateFormatted,
          generateRefCode: this.form.generateRefCode
            ? this.form.generateRefCode.value
            : null,
          memberClassCode: this.form.memberClassCode
            ? this.form.memberClassCode.value
            : null,
          registerStatus: this.form.registerStatus
            ? this.form.registerStatus.value
            : null,
          expirationStatus: this.form.expirationStatus
            ? this.form.expirationStatus.value
            : null,
        })
          .then(({ data }) => {
            resolve({
              items: data.items,
              total: data.total,
            });
          })
          .finally(() => {
            this.datatable.loading = false;
          });
      });
    },
    getGenerateRefCodeFromApi() {
      this.form.generateRefCodeLoading = true;
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/MemberCards/GenerateRefCodeOptions", {
          query: this.form.generateRefCodeSearch,
        })
          .then(({ data }) => {
            resolve(data);
          })
          .finally(() => {
            this.form.generateRefCodeLoading = false;
          });
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month] = date.split("-");
      return `${month}/${year}`;
    },
    formatRegDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    Export() {
      var SortBy = "";
      var SortDesc = "";

        this.datatable.options.sortBy.forEach((val) => {
          SortBy += '&SortBy='+val;
        });
        this.datatable.options.sortDesc.forEach((val) => {
          SortDesc += '&SortDesc='+val;
        });
        // var ExpireMonth = this.form.expireMonth
        //   ? parseInt(this.form.expireMonth.value)
        //   : "";
        // var RegisteredDate = this.form.registeredDate
        //   ? parseInt(this.form.registeredDate.value)
        //   : "";
        if (this.form.generateRefCode == null) this.form.generateRefCode = ""
        if (this.form.memberClassCode == null) this.form.memberClassCode = ""
        if (this.form.registerStatus == null) this.form.registerStatus = ""
        if (this.form.expirationStatus == null) this.form.expirationStatus = ""
      window.open(
        "/Files/Export/TotalMemberCard?searchKeyword=" + this.form.searchKeyword +
          "&generateRefCode=" + this.form.generateRefCode +
          "&memberClassCode=" + this.form.memberClassCode +
          "&registerStatus=" + this.form.registerStatus +
          "&expirationStatus=" + this.form.expirationStatus +
          "&expireMonth=" + this.form.expireMonthFormatted + 
          "&registeredDate=" + this.form.registeredDateFormatted +
          SortBy + SortDesc +
          "&token=" + this.token,
        "_blank"
      );
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.MEMBER_CARD.SECTION"), route: "/MemberCards" },
      { title: this.$t("MENU.MEMBER_CARD.SEARCH") },
    ]);
  },
  computed: {
    title() {
      return this.$t("MENU.MEMBER_CARD.SEARCH");
    },
    token() {
      return JwtService.getToken();
    },
    computedRegisteredDate() {
      return this.formatRegDate(this.form.registeredDate)
    },
    computedExpireMonth() {
      return this.formatDate(this.form.expireMonth)
    },
    minExpireMonth() {
      return new Date().toISOString().substr(0, 7);
    },
  },
  watch: {
    "datatable.options": {
      handler() {
        this.getDataFromApi().then((data) => {
          this.datatable.total = data.total;
          this.datatable.items = data.items;
        });
      },
      deep: true,
    },
    "form.generateRefCodeSearch": {
      handler() {
        this.getGenerateRefCodeFromApi().then((data) => {
          this.form.generateRefCodeItems = data;
        });
      },
    },
    "form.registeredDate": {
      handler() {
         this.form.registeredDateFormatted = this.formatRegDate(this.form.registeredDate);
      }
    },
    "form.expireMonth": {
      handler() {
         this.form.expireMonthFormatted = this.formatDate(this.form.expireMonth);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
